import dayjs from "dayjs";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { formatSsn, formatZipcode } from "forms/hooks/customHook";

export const errorMessage = (error: any, defaultMessage: string): any => {
  if (error?.response?.data?.message) {
    if (Array.isArray(error?.response?.data?.message)) {
      return error?.response?.data?.message[0];
    }
    return error?.response?.data?.message;
  } else if (error?.data?.message) {
    return error?.data?.message;
  }
  return defaultMessage;
};

/* phone number format masked */
export function parsePhoneNumberformatMasked(
  countryCode: any,
  mobileNumber: any
) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = `+${countryCode}${mobileNumber}`;
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, "ZZ");

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const formattedNumber = phoneNumberUtil.format(
      parsedNumber,
      PhoneNumberFormat.INTERNATIONAL
    );
    let maskedValue = formattedNumber.replace(/[a-zA-Z0-9]/g, "X"); // Replace all alphanumeric characters with 'X'
    const newStr = maskedValue.slice(0, -3);
    const str = formattedNumber.substring(12);
    const updatedMobile = newStr + str;
    return updatedMobile;
  } catch (error) {
    return null; // Invalid phone number
  }
}

export function getFormatedDisplayTime(time: any) {
  try {
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(time));
  } catch (error) {
    return null;
  }
}

/* date field masked */
export function dateOfBirthMask(date: string) {
  try {
    const maskedDob = dayjs(date)?.format("MM/DD/YYYY") || "-";
    const parts = maskedDob.split("/");
    const maskedDay = "XX";
    const maskedMonth = "XX";
    const year = "X" + parts[2].substring(1);
    return `${maskedMonth}/${maskedDay}/${year}`;
  } catch (error) {
    return null;
  }
}

/* set profile details */
export const setProfileDetail = (data: any) => {
  return {
    firstname: data?.firstname && data?.firstname,
    middlename: data?.middlename && data?.middlename,
    lastname: data?.lastname && data?.lastname,
    email: data?.email && data?.email,
    gender: data?.gender && data?.gender,
    ssn: data?.ssn && formatSsn(data?.ssn),
    dob: data?.dob ? dayjs(data?.dob) : (null as any),
    mobile_number: data?.mobile_number && data?.mobile_number,
    country_of_birth: data?.country_of_birth
      ? ({ name: data?.country_of_birth, value: data?.country_of_birth } as any)
      : null,
    home_phone: data?.home_phone && data?.home_phone,
    office_phone: data?.office_phone && data?.office_phone,
    english_fluency:
      (data?.english_fluency && data?.english_fluency?.toString()) || null,
    preferred_language: data?.preferred_language && data?.preferred_language,
    other_language: data?.other_language && data?.other_language,
    secondary_phone: data?.secondary_phone && data?.secondary_phone,
    tnc_affiliation: data?.tnc_affiliation && data?.tnc_affiliation,
    address_line_1: data?.address_line_1 && data?.address_line_1,
    address_line_2: data?.address_line_2 && data?.address_line_2,
    city: data?.city && data?.city,
    state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    zip_code: data?.zip_code && formatZipcode(data.zip_code),
    version: data?.version,
  };
};

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

// Create an array of the previous 20 years
export const previous20Years = Array.from({ length: 125 }, (_, index) => {
  const year = currentYear - index;
  return { name: year.toString(), value: year.toString() };
});

export const accidentPoints = Array.from({ length: 31 }, (_, index) => {
  return { name: index.toString(), value: index.toString() };
});

export async function base64ToFile(url: string, filename: string, type: string) {
  return new Promise((resolve, reject) => {
  fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], filename,{ type: `${type}` });
      resolve(file);
    }).catch((err) => {
      reject(err);
    })
  })
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}


export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  imageType: string,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc) as any;
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas') as any;

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  return await base64ToFile(croppedCanvas.toDataURL(`image/${imageType}`), `img`, imageType);

  // As a blob
  // return new Promise((resolve, reject) => {
  //   croppedCanvas.toBlob((file: any) => {
  //     console.log("file", file);
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
}